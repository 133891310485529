import { useCallback, useRef } from 'react';
import { useOnMount } from '@common/hooks/useOnMount';

export const useTimeout = () => {
	const timeoutHandler = useRef(null);

	const _setTimeout = useCallback((callback, timeout) => {
		if (timeoutHandler.current) {
			clearTimeout(timeoutHandler.current);
			timeoutHandler.current = null;
		}
		if (timeout) {
			timeoutHandler.current = setTimeout(() => {
				callback();
			}, timeout);
		}
	}, []);

	useOnMount(() => () => {
		clearTimeout(timeoutHandler.current);
	});

	return {
		setTimeout: _setTimeout,
	};
};

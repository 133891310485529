import { BadgeStyledProps } from '@ui/badge';
import { css } from 'styled-components';
import { Size } from '@uiTypes/Size';

export const BadgeSizeMixin = (props: BadgeStyledProps) => {
	const { $size } = props;

	if ($size === Size.MEDIUM) {
		return css`
			padding: 0 6px;
		`;
	}

	return css`
		padding: 2px 8px;
	`;
};

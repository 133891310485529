import styled, { css } from 'styled-components';

const getMobileFontSize = (props) => {
	// 80 / 50
	const fontScale = 80 / 50;
	let fontSize = (props.$screenWidth / (props.$length || 3)) * 0.8 * fontScale;
	if (fontSize > (props.$noTarget ? 40 : 80)) {
		fontSize = props.$noTarget ? 40 : 80;
	}
	return css`
		font-size: ${fontSize}px;
		i,
		svg {
			font-size: ${fontSize * 0.6}px;
			margin-left: ${fontSize / fontScale / 4}px;
			padding-top: ${fontSize * 0.3}px;
		}
	`;
};

export const StyledLimboCounter = styled.div.attrs({
	className: 'game--limbo__result',
})`
	${(props) =>
		props.$screenWidth
			? getMobileFontSize(props)
			: css`
					font-size: ${props.$noTarget ? '50' : '100'}px;
			  `};
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 10px 0;
	white-space: nowrap;
	width: 100%;

	${(props) =>
		props.$screenWidth
			? ''
			: css`
					i,
					svg {
						font-size: ${props.$noTarget ? '40' : '70'}px;
						margin-left: ${props.$noTarget ? '10' : '20'}px;
						padding-top: ${props.$noTarget ? '15' : '25'}px;
					}
			  `};

	@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
		${(props) =>
			props.$screenWidth
				? ''
				: css`
						font-size: ${props.$noTarget ? '40' : '80'}px;
				  `};
		margin: 5px 0 0;

		${(props) =>
			props.$screenWidth
				? ''
				: css`
						i,
						svg {
							font-size: ${props.$noTarget ? '28' : '50'}px;
							margin-left: ${props.$noTarget ? '5' : '10'}px;
							padding-top: ${props.$noTarget ? '12' : '20'}px;
						}
				  `};
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthVerySmall}) {
		margin: 0;
	}
`;

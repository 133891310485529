import { Svg } from '@legacyApp/client/components/svg/Svg';

export const ArrowIcon = () =>
	Svg({
		content: (
			<>
				<path
					d="M537.783,613v-18.84a3.005,3.005,0,0,1,4.508-2.6l16.316,9.42,16.316,9.42a3.005,3.005,0,0,1,0,5.205l-16.316,9.42-16.316,9.42a3,3,0,0,1-4.508-2.6Z"
					transform="translate(-537.783 -591.152)"
					fill="#1dbb2f"
				/>
			</>
		),
		width: 38,
		height: 43,
	});

import styled, { css } from 'styled-components';

interface AspectRatioWrapperProps {
	ratio?: number;
	maxHeight?: number | string;
	overflow?: string;
	height?: string;
}

export const StyledAspectRatioWrapper = styled.div<AspectRatioWrapperProps>`
	${(props) =>
		props.height
			? css`
					height: ${props.height};
			  `
			: ''}
	${(props) =>
		props.maxHeight
			? css`
					max-height: ${props.maxHeight};
			  `
			: ''}
	${(props) =>
		props.overflow
			? css`
					overflow: ${props.overflow};
			  `
			: ''}
	position: relative;
	${(props) =>
		props.ratio
			? css`
					:before {
						content: '';
						display: block;
						padding-top: ${(1 / props.ratio) * 100}%;
					}
			  `
			: ''}
`;

StyledAspectRatioWrapper.defaultProps = {
	ratio: 16 / 9,
	maxHeight: 'auto',
};

export const StyledAspectRatioInner = styled.div`
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	transition: 300ms;
	width: 100%;
`;

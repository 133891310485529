import React, { FC } from 'react';
import styled from 'styled-components';
import { parseImageSrc } from '@common/components/default/Image/Image';
import Icon from '../../../common/components/icons/Icon';

const FallbackImage = {
	microgaming: true,
};

export const ProviderLogo: FC<{
	slug: string;
	name?: string;
}> = ({ slug, name }) => {
	if (!slug) {
		return null;
	}
	return (
		<ProviderLogoStyled>
			<Icon
				id={`slots/providers/${slug}`}
				fallbackImg={
					FallbackImage[slug]
						? parseImageSrc(`/img/slots/providers/${slug}.png`)
						: null
				}
				name={name}
			/>
		</ProviderLogoStyled>
	);
};

export const ProviderLogoStyled = styled.span`
	display: inline-block;
	height: 100%;
	max-height: 100%;
	overflow: hidden;
	width: 100%;

	> svg {
		fill: white;
		height: 100%;
		width: 100%;
	}

	> img {
		width: 100%;
	}

	> span {
		align-items: center;
		display: flex;
		font-size: 1.3rem;
		height: 100%;
		justify-content: center;
		text-align: center;
	}
`;

import { FC } from 'react';
import styled, { css } from 'styled-components';
import { Badge, BadgeStyled, BadgeType } from '@ui/badge';
import { ArrowIcon } from '@icons/slots/ArrowIcon';
import { generateUrl, ROUTE } from 'src/common/routes';
import {
	StyledAspectRatioInner,
	StyledAspectRatioWrapper,
} from 'src/common/components/AspectRatioWrapper/AspectRatioWrapper.styled';
import { Slot } from 'src/modules/slots/store/slots.types';
import { PROVIDER_ADMIN_ID_FROM } from 'src/modules/slots/hooks/useFetchProviders';
import { BalanceConvertedCurrencies } from 'src/modules/balance/store/balance.constants';
import { BalanceAmount } from 'src/common/components/number/balanceAmount';
import { LinkTo } from 'src/common/components/default/LinkTo/LinkTo';
import { roundNumber } from 'src/common/methods/roundNumber/roundNumber';
import { SlotInfo } from 'src/modules/slots/components/SlotInfo';
import { SlotDropsAndWinsWatermark } from 'src/modules/slots/components/SlotDropsAndWinsWatermark';
import {
	GameImage,
	GameImageStyled,
} from 'src/modules/slots/components/GameImage';
import { ProviderLogoStyled } from 'src/modules/slots/components/ProviderLogo';

export const SlotTile: FC<Slot> = (game) => {
	const {
		name,
		image_url,
		provider_name,
		provider_slug,
		rtp_value,
		slug,
		view_aspect_ratio,
		provider_id,
		amount_usd,
	} = game;

	return (
		<LinkTo
			passHref
			forceATag
			href={
				!slug
					? ''
					: generateUrl(
							provider_id < PROVIDER_ADMIN_ID_FROM
								? ROUTE.slotPage
								: ROUTE.slotAdminPage,
							{ slug },
					  )
			}
		>
			<StyledSlotTileContainer>
				<StyledSlotTileFeatured>
					<StyledSlotTileInfo>
						<ArrowIcon />
						{!!rtp_value && (
							<StyledSlotTileRTP>RTP: {rtp_value}%</StyledSlotTileRTP>
						)}
					</StyledSlotTileInfo>
					<SlotDropsAndWinsWatermark {...game} />
					<StyledImageWrapper
						$isBadge={!!amount_usd}
						$src={image_url}
						ratio={view_aspect_ratio}
					>
						<StyledAspectRatioInner>
							<StyledSlotsTileImage>
								<GameImage {...game} />
							</StyledSlotsTileImage>
						</StyledAspectRatioInner>
						{amount_usd && (
							<Badge type={BadgeType.SUCCESS}>
								<BalanceAmount
									noFormat
									className="text-style-xs-medium"
									value={roundNumber(amount_usd, 2).toFixed(2)}
									balanceConverted={BalanceConvertedCurrencies.USD}
								/>
							</Badge>
						)}
					</StyledImageWrapper>
				</StyledSlotTileFeatured>
				{/*<pre>{JSON.stringify(game, undefined, 2)}</pre>*/}
				{name && provider_slug && provider_name && (
					<SlotInfo
						slotName={name}
						providerSlug={provider_slug}
						providerName={provider_name}
					/>
				)}
			</StyledSlotTileContainer>
		</LinkTo>
	);
};

const StyledSlotTileContainer = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const StyledSlotsTileImage = styled.div`
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	width: 100%;

	${GameImageStyled} {
		height: 100%;
	}

	${ProviderLogoStyled} {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		width: 100%;

		img,
		svg {
			width: 80%;
		}
	}
`;

const StyledSlotTileInfo = styled.div`
	align-items: center;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	opacity: 0;
	position: absolute;
	transition: 300ms;
	width: 100%;
	z-index: 2;
`;

const StyledSlotTileRTP = styled.div`
	bottom: 10px;
	font-family: ${(props) => props.theme.text.fonts.Regular};
	font-size: 8px;
	position: absolute;
	right: 20px;
`;

export const StyledSlotTileFeatured = styled.div`
	background-color: #030f1e;
	border-radius: 12px;
	flex-grow: 1;
	height: auto;
	overflow: hidden;
	position: relative;

	&:hover {
		${StyledSlotTileInfo} {
			opacity: 1;
		}

		${StyledAspectRatioInner} {
			transform: scale(1.05);
		}

		${BadgeStyled} {
			bottom: -30px;
		}
	}
`;

const StyledImageWrapper = styled(StyledAspectRatioWrapper)<{
	$src: string;
	$isBadge?: boolean;
}>`
	position: relative;

	&:before {
		background: url(${(props) => props.$src});
		background-size: cover;
		background-size: 100% 100%;
		filter: blur(10px);
	}

	${StyledAspectRatioInner} {
		display: flex;
		justify-content: center;
	}

	${BadgeStyled} {
		bottom: 8px;
		left: 8px;
		position: absolute;
		z-index: 3;
	}

	${(props) =>
		props.$isBadge
			? css`
					box-shadow: inset 0 -20px 40px -20px black;
					position: relative;

					:after {
						//box-shadow: inset 0 -20px 40px -20px black;
						background: linear-gradient(to top, #0e1f33 0%, transparent 70%);
						content: '';
						display: block;
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;
						z-index: 1;
					}
			  `
			: null}
`;

import { FC } from 'react';
import styled from 'styled-components';
import { Image } from '@common/components/default/Image/Image';
import { GameDetailsDTO, Slot } from '../store/slots.types';

const checkIsDropsAndWins = (game: Slot | GameDetailsDTO) => {
	if ('tags' in game && !!game?.tags?.length) {
		return game.tags?.indexOf('drops-and-wins') > -1;
	}
	if ('categories' in game && !!game?.categories?.length) {
		return (
			game.categories?.findIndex(
				(category) => category.slug === 'drops-and-wins',
			) > -1
		);
	}
	return false;
};

export const SlotDropsAndWinsWatermark: FC<Slot | GameDetailsDTO> = (game) => {
	if (!checkIsDropsAndWins(game)) {
		return null;
	}

	return (
		<StyledSlotWaterMark>
			<Image src={'/img/slots/dropsandwins.png'} alt={'Drops and wins logo'} />
		</StyledSlotWaterMark>
	);
};

export const StyledSlotWaterMark = styled.div`
	height: 35%;
	left: 0;
	position: absolute;
	top: 0;
	z-index: 1;

	:before {
		content: '';
		background: radial-gradient(
			circle,
			var(--color-blue-dark) 0%,
			var(--color-blue-dark) 15%,
			rgba(255, 255, 255, 0) 40%
		);
		position: absolute;
		height: 400%;
		display: block;
		width: 400%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 0;
	}

	img {
		height: 100%;
		position: relative;
		z-index: 1;
	}
`;

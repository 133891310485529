import { useCallback, useContext } from 'react';
import { useFetchApiPromise } from '@legacyApp/hooks/fetch/useFetchApi';
import { UseGetDataArrayCallback } from '@legacyApp/hooks/fetch/useGetDataArray';
import { useUserIsAdmin, useUserName } from '@common/selectors/user.selectors';
import { ProviderDTO } from '../store/slots.types';
import { generateFetchSlotProvidersUrl } from '../services/Slots.service';
import { SlotsProvidersContext } from '../context/SlotsProvidersContext';

export const PROVIDER_ADMIN_ID_FROM = 1000;
export const USERS_ALLOWED_TO_SEE_ADMIN_PROVIDERS = ['fundist1', 'dymekk12'];

export const useFetchProviders = () => {
	const fetchApi = useFetchApiPromise();
	const contextProviders = useContext(SlotsProvidersContext);
	const userIsAdmin = useUserIsAdmin();
	const userName = useUserName();

	const callback: UseGetDataArrayCallback<ProviderDTO> = useCallback(
		async (take?: number) => {
			const { list, count } = await fetchApi({
				url: generateFetchSlotProvidersUrl({
					...((userIsAdmin ||
						USERS_ALLOWED_TO_SEE_ADMIN_PROVIDERS.indexOf(userName) > -1) && {
						isAdmin: true,
					}),
					...(take && { take }),
				}),
				fromState: true,
				disableErrorHandler: true,
			})
				.then(
					(
						result:
							| { list: ProviderDTO[]; count: number }
							| { data: ProviderDTO[] },
					) => {
						if (
							typeof result === 'object' &&
							'data' in result &&
							result?.data?.length
						) {
							return {
								list: result.data,
								count: result.data.length,
							};
						}
						if (
							typeof result === 'object' &&
							'list' in result &&
							result?.list?.length
						) {
							return {
								list: result.list,
								count: result?.count,
							};
						}
						return contextProviders?.providers;
					},
				)
				.catch((error) => {
					console.error(error);
					return contextProviders?.providers;
				});

			if (contextProviders?.setProviders) {
				contextProviders.setProviders(
					{ list, count } || contextProviders?.providers,
				);
			}

			return {
				data: list || contextProviders?.providers?.list,
				count: count || contextProviders?.providers?.count,
			};
		},
		[contextProviders, fetchApi, userIsAdmin],
	);

	return {
		callback,
		options: {
			data: contextProviders?.providers?.list,
			count: contextProviders?.providers?.count,
		},
	};
};

import { useCallback, useState } from 'react';
import { useFetchApi } from '@legacyApp/hooks/fetch/useFetchApi';
import { useTimeout } from '@legacyApp/hooks/app/useTimeout';
import { Slot, SlotSearchFetchData } from '../store/slots.types';
import { generateSearchUrl } from '../services/Slots.service';
import { normalizeSlots } from '../services/Slots.mapper';

const DEFAULT_LOADING = false;
const DEFAULT_RESULTS = [];

export const useFetchSearchTerm = () => {
	const { fetch, cancel } = useFetchApi();
	const { setTimeout } = useTimeout();

	const [term_, setTerm] = useState('');
	const [loading_, setLoading] = useState(DEFAULT_LOADING);
	const [results, setResults] = useState<Slot[]>(DEFAULT_RESULTS);

	const fetchSearch = useCallback(
		async (term: string): Promise<SlotSearchFetchData> => {
			cancel();
			return await fetch({
				url: generateSearchUrl(term),
			}).then((value) => value);
		},
		[cancel, fetch],
	);

	const setTermCallback = useCallback(
		(term) => {
			setTerm(term);
			if (term && term.length > 1) {
				setLoading(true);
				setTimeout(() => {
					fetchSearch(term).then((response) => {
						if (response?.error) {
							return setLoading(false);
						}
						const slots = normalizeSlots(response?.data || []);
						setTimeout(() => {
							setLoading(false);
							setResults(slots);
						}, 500);
					});
				}, 300);
			} else {
				setResults(DEFAULT_RESULTS);
			}
		},
		[fetchSearch, setTimeout],
	);

	return {
		loading: loading_,
		results,
		setTerm: setTermCallback,
		term: term_,
	};
};

import { FunctionComponent, useCallback } from 'react';
import styled from 'styled-components';
import {
	trans,
	transSlots,
} from '@legacyApp/client/modules/translation/translate';
import { InputWrapperContainer } from '@legacyApp/client/components/input/inputWrapper.container';
import { simpleTrans } from '@legacyApp/client/modules/translation/translationService';
import { boxNoHeight } from '@legacyApp/client/modules/style/defaults';
import { Loader } from '@legacyApp/client/components/loader/loader';
import { TransNamespace } from '@legacyApp/client/modules/translation/TransNamespace';
import { useGetDataArray } from '@legacyApp/hooks/fetch/useGetDataArray';
import { useFetchSearchTerm } from '../../hooks/useFetchSearchTerm';
import { useFetchGames } from '../../hooks/useFetchGames';
import { StyledBetModal } from '../../../modals/components/BetModal/BetModal.styled';
import { Slot } from '../../store/slots.types';
import { SlotsSearchResult } from './SlotsSearchResult';

export const StyledNoResults = styled.div`
	display: flex;
	justify-content: center;
	margin: 1rem;
`;

export const useFetchSearchDefault = () => {
	const { callback } = useFetchGames();
	const gamesCallback = useCallback(
		() =>
			callback(
				{
					orderBy: 'top',
					count: 5,
				},
				{ fromState: true },
			),
		[callback],
	);
	const { data: list } = useGetDataArray<Slot>('top', gamesCallback);

	return {
		list,
	};
};

const SlotsSearchModal: FunctionComponent = () => {
	const { results, loading, setTerm, term } = useFetchSearchTerm();
	const { list } = useFetchSearchDefault();

	return (
		<div className="user-modal user-select-text">
			<h3 className="user-modal__title">{trans({ label: 'Search' })}</h3>
			<StyledBetModal
				className={'user-modal__top-section flex-start-column no-border'}
			>
				<InputWrapperContainer
					value={term}
					name={'slots-search-query'}
					onChangeDelayed={setTerm}
					noTranslatePlaceholder
					placeholder={`${simpleTrans(
						'Game',
						undefined,
						TransNamespace.SLOTS,
					)}, ${simpleTrans(
						'Providers',
						undefined,
						TransNamespace.SLOTS,
					)}, ${simpleTrans('Category', undefined, TransNamespace.SLOTS)}...`}
				/>
				<StyledSearchResultBox>
					<Loader active={loading} />
					{term.length ? (
						results?.length ? (
							results.map((slot) => (
								<SlotsSearchResult
									key={`${slot.game_id}_${slot.operator_id}`}
									slot={slot}
								/>
							))
						) : loading ? null : (
							<StyledNoResults>
								{trans({ label: 'No results' })}
							</StyledNoResults>
						)
					) : null}
				</StyledSearchResultBox>
				{!results?.length && !!list?.length && (
					<StyledSearchResultBox>
						<h3 className="margin-top no-margin-bottom">
							{transSlots({ label: 'Try our recommended games' })}:
						</h3>
						{list.map((slot) => (
							<SlotsSearchResult
								key={`${slot.game_id}_${slot.operator_id}`}
								slot={slot}
							/>
						))}
					</StyledSearchResultBox>
				)}
			</StyledBetModal>
		</div>
	);
};

export const StyledSearchResultBox = styled.div`
	${boxNoHeight};
	min-height: 30px;
	position: relative;
`;

export default SlotsSearchModal;

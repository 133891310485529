import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { sequenceArray } from '@legacyApp/client/modules/app/appService';
import { isFunction } from '../../methods/isFunction';

const LoaderListWrapper = ({
	list,
	listElement,
	loaderWrapper,
	loaded,
	preloadLength,
	preloadElement,
	listElementCallback,
	noDataElement,
}) => {
	// console.log('LoaderListWrapper', {
	// 	list,
	// 	listElement,
	// 	loaderWrapper,
	// 	loaded,
	// 	preloadLength,
	// 	preloadElement,
	// 	listElementCallback,
	// 	noDataElement,
	// });
	if (list?.length) {
		return listElement || list.map(listElementCallback);
	}
	if (!loaded && preloadLength && preloadElement) {
		const Wrapper = loaderWrapper || Fragment;
		return (
			<Wrapper>
				{sequenceArray(preloadLength).map((el, index) =>
					isFunction(preloadElement)
						? preloadElement(el, index)
						: preloadElement,
				)}
			</Wrapper>
		);
	}
	if (loaded && noDataElement) {
		return noDataElement;
	}
	return null;
};

LoaderListWrapper.propTypes = {
	list: PropTypes.array,
	listElement: PropTypes.node,
	loaderWrapper: PropTypes.any,
	loaded: PropTypes.bool,
	preloadLength: PropTypes.number,
	preloadElement: PropTypes.any,
	listElementCallback: PropTypes.func,
	noDataElement: PropTypes.any,
};

export { LoaderListWrapper };

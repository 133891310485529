import { Observable, of } from 'rxjs';
import { Mapper } from '@common/types/mapper.types';
import { Slot, SlotDTO } from '../store/slots.types';

export const mapSlotDTMtoSlot = (unmappedSlot: SlotDTO): Slot => {
	return {
		...unmappedSlot,
		view_aspect_ratio: (eval(unmappedSlot.view_aspect_ratio) as number) || 1,
	};
};

export const normalizeSlots = (unmappedSlots: SlotDTO[]): Slot[] => {
	if (!unmappedSlots.length) {
		return [];
	}

	return unmappedSlots.map(mapSlotDTMtoSlot);
};

export class SlotsMapper implements Mapper<SlotDTO[], Slot[]> {
	private mapSlots(unmappedSlots: SlotDTO[]) {
		return unmappedSlots.map(mapSlotDTMtoSlot);
	}

	public map(unmappedSlots: SlotDTO[]): Observable<Slot[]> {
		if (!unmappedSlots.length) {
			return of([]);
		}
		const mappedSlots: Slot[] = this.mapSlots(unmappedSlots);
		return of(mappedSlots);
	}
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import { isFunction } from '@common/methods/isFunction';
import { usePrevious } from '../render/usePrevious';

export interface UseGetDataArrayOptions<T> {
	loaded?: boolean;
	data?: T[];
	count?: number;
	loading?: boolean;
}

export type UseGetDataArrayCallback<T, Q = any> = (
	query?: Q,
	props?: any,
) => Promise<{ data: T[]; count?: number }>;

export const useGetDataArray = <T = any, Q = any>(
	key: string,
	callback: UseGetDataArrayCallback<T, Q>,
	options?: UseGetDataArrayOptions<T>,
): {
	loaded?: boolean;
	data?: T[];
	count?: number;
	loading?: boolean;
	update?: () => Promise<void>;
} => {
	const defaults = useMemo(
		() => ({
			loaded: options?.loaded || false,
			data: options?.data || [],
			count: options?.count || 0,
			loading: options?.loading || false,
		}),
		[options?.loaded, options?.data, options?.count, options?.loading],
	);

	const [loaded, setLoaded] = useState(defaults.loaded);
	const [loading, setLoading] = useState(defaults.loading);
	const [data, setData] = useState(defaults.data);
	const [count, setCount] = useState(defaults.count);

	const prevKey = usePrevious(key);

	const update = useCallback(async () => {
		if (!isFunction(callback) || loading) {
			return;
		}
		setLoading(true);
		const { data, count } = await callback().catch((error) => {
			console.error(error);
			return defaults;
		});
		if (data === null) {
			setLoading(false);
			return;
		}
		setData(data);
		if (count) {
			setCount(count);
		}
		setLoading(false);
		setLoaded(true);
	}, [callback, defaults, loading]);

	useEffect(() => {
		if (key !== prevKey || (!loaded && !loading)) {
			update();
		}
	}, [key, loaded, loading, prevKey, update]);

	return {
		loaded,
		loading,
		data: data?.length ? data : options?.data || [],
		count: count || options?.count,
		update,
	};
};

import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { BadgeTypeMixin } from '@ui/badge/mixins/BadgeType.mixin';
import { BadgeSizeMixin } from '@ui/badge/mixins/BadgeSize.mixin';
import classnames from 'classnames';
import { Size } from '@uiTypes/Size';

export enum BadgeType {
	SUCCESS = 'success',
	RAINBOW = 'rainbow',
	NEUTRAL = 'neutral',
	ORANGE = 'orange',
}

export interface BadgeProps {
	type?: BadgeType;
	size?: Size;
	className?: string;
}

export const Badge: FC<PropsWithChildren<BadgeProps>> = ({
	children,
	type,
	size,
	className,
}) => {
	return (
		<BadgeStyled
			className={classnames('text-style-sm-semibold', className)}
			$size={size}
			$type={type}
		>
			{children}
		</BadgeStyled>
	);
};

export interface BadgeStyledProps {
	$type: BadgeType;
	$size?: Size;
}

export const BadgeStyled = styled.div.attrs<BadgeStyledProps>((props) => ({
	className: props.$type,
}))<BadgeStyledProps>`
	align-items: center;
	border-radius: 20px;
	display: inline-flex;
	justify-content: center;
	transition: 300ms;
	width: auto;

	${BadgeTypeMixin};
	${BadgeSizeMixin};
`;

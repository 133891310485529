import styled, { css } from 'styled-components';
import { boxNoHeight } from '../../modules/style/defaults';

export const CheckboxStyle = styled.div.attrs(() => ({
	className: 'checkbox',
}))`
	${boxNoHeight};
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	label {
		position: relative;
		margin-bottom: 0 !important;
	}

	[type='checkbox'] {
		pointer-events: none;
	}
`;

const getWrapper = (props) => {
	let color = 'var(--color-dark-600)';
	if (props.isColor) {
		color = props.theme.colors.active;
	}
	if (props.isError) {
		color = props.theme.colors.negative;
	}
	return css`
		background-color: ${color};
		border: 1px solid var(--color-dark-400);
	`;
};

export const CheckboxWrapperStyle = styled.div.attrs(() => ({
	className: 'checkbox__wrapper',
}))`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-right: 20px;

	[type='checkbox'] {
		opacity: 0;
	}
`;

export const StyledCheckboxBox = styled.div`
	border-radius: 4px;
	display: block;
	height: 16px;
	position: absolute;
	width: 16px;
	${getWrapper}

	svg {
		height: 75%;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);

		path {
			fill: white;
		}
	}
`;

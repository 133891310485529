import styled from 'styled-components';
import { StyledGameSlider } from '@modules/games/DiceGame/components/GameSlider/GameSlider.styled';
import { PlinkoResultStyled } from '../../../games/PlinkoGame/components/PlinkoResult/PlinkoResult.styled';
import { StyledLimboCounter } from '../../../games/LimboGame/LimboCounter/LimboCounter.styled';
import { BetDetailsStyled } from './BetDetails';

export const StyledBetModal = styled.div`
	margin: 0 -12px;
	overflow-x: hidden;
	padding: 0 12px;
	width: calc(100% + 24px);

	&:first-of-type {
		margin-top: -12px;
	}

	${BetDetailsStyled} {
		padding-bottom: 12px;
		margin-bottom: 24px;
		border-bottom: 1px solid var(--color-dark-600);
	}

	${PlinkoResultStyled} {
		margin: 12px 0 24px;
	}

	${StyledLimboCounter} {
		margin: 24px 0 24px;
	}

	${StyledGameSlider} {
		margin-left: -45px !important;
		padding-left: 60px !important;
		padding-right: 60px !important;
		width: calc(100% + 90px) !important;

		@media (max-width: ${(props) => props.theme.media_v2.max_tablet_medium}) {
			padding-left: 70px !important;
			padding-right: 70px !important;
		}
	}
`;

import { BadgeStyledProps, BadgeType } from '@ui/badge';
import { css } from 'styled-components';

export const BadgeTypeMixin = (props: BadgeStyledProps) => {
	const { $type } = props;

	if ($type === BadgeType.RAINBOW) {
		return css`
			background: var(--color-misc-rainbow);
			color: var(--color-white);
		`;
	}

	if ($type === BadgeType.SUCCESS) {
		return css`
			background-color: var(--color-other-success);
			color: var(--color-white);
		`;
	}

	if ($type === BadgeType.ORANGE) {
		return css`
			background-color: var(--color-other-orange);
			color: var(--color-white);
		`;
	}

	if ($type === BadgeType.NEUTRAL) {
		return css`
			background-color: var(--color-dark-300);
			color: var(--color-white);
		`;
	}

	return css`
		background-color: var(--color-misc-secondary-button);
		color: var(--color-white);
	`;
};

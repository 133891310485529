import { FC } from 'react';
import styled from 'styled-components';
import { Slot } from '../store/slots.types';
import { ProviderLogo } from './ProviderLogo';

export const GameImage: FC<Slot> = ({ image_url, name, provider_slug }) => {
	if (!image_url) {
		return <ProviderLogo slug={provider_slug} />;
	}
	return <GameImageStyled src={image_url} alt={name} />;
};

export const GameImageStyled = styled.img``;

import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { LinkTo } from '@common/components/default/LinkTo/LinkTo';
import { useAppDispatch } from '@legacyApp/hooks/store/useAppDispatch';
import { closeModal } from '../../../modals/store/modal.actions';
import { Slot } from '../../store/slots.types';
import { generateSlotUrl } from '../../services/Slots.service';
import { SlotDropsAndWinsWatermark } from '../SlotDropsAndWinsWatermark';
import { StyledSlotTileFeatured } from '../SlotTile';
import { GameImage, GameImageStyled } from '../GameImage';
import { ProviderLogoStyled } from '../ProviderLogo';

export const SlotsSearchResult: FunctionComponent<{
	slot: Slot;
}> = ({ slot }) => {
	const dispatch = useAppDispatch();
	return (
		<StyledResult>
			<LinkTo
				href={generateSlotUrl(slot)}
				onClick={() => dispatch(closeModal())}
			>
				<StyledResultLayout>
					<div>
						<StyledSlotTileFeatured>
							<SlotDropsAndWinsWatermark {...slot} />
							<StyledSlotsImage>
								<GameImage {...slot} />
							</StyledSlotsImage>
						</StyledSlotTileFeatured>
					</div>
					<div>
						<StyledResultName>{slot.name}</StyledResultName>
						<StyledResultProvider>{slot.provider_name}</StyledResultProvider>
					</div>
				</StyledResultLayout>
			</LinkTo>
		</StyledResult>
	);
};

export const StyledResult = styled.div`
	&:not(:last-of-type) {
		border-bottom: 1px solid rgba(255, 255, 255, 0.4);
	}
`;

export const StyledResultLayout = styled.div`
	align-items: center;
	display: grid;
	font-family: ${(props) => props.theme.text.fonts.Regular};
	gap: 1px 24px;
	grid-template-areas: '. .';
	grid-template-columns: 0.3fr 0.7fr;
	grid-template-rows: 1fr;
	justify-content: center;
	padding: 1.5rem 1rem;
	width: 100%;
`;

export const StyledResultName = styled.div`
	font-size: 18px;
`;

export const StyledResultProvider = styled.div`
	font-size: 12px;
	opacity: 0.4;
`;

export const StyledSlotsImage = styled.div`
	display: grid;
	width: 100%;

	${GameImageStyled} {
		width: 100%;
	}

	${ProviderLogoStyled} {
		align-items: center;
		display: flex;
		height: 80px;
		justify-content: center;
		width: 100%;

		img,
		svg {
			width: 80%;
		}
	}
`;

import styled, { css } from 'styled-components';
import { Risk } from '../../types';
import { getBallColor } from '../playground/PixiJS/methods/getBallColor';

interface PlinkoGameBodyStyledProps {
	$resultIndex?: number;
	$risk?: Risk;
}

const BALL_SIZE = 15;
const BORDER_SIZE = 1;

const getMultipliersWidth = (props: PlinkoGameBodyStyledProps) => {
	const colors = getBallColor(props.$risk);

	return css`
		background-color: ${colors.fill};
		border: ${BORDER_SIZE}px solid ${colors.line};
	`;
};

export const PlinkoResultStyled = styled.div<PlinkoGameBodyStyledProps>`
	padding-bottom: 10px;
	padding-top: 20px;
	width: 100%;
`;

import { createContext } from 'react';
import { ProviderDTO } from '../store/slots.types';

export const SlotsProvidersContext = createContext<{
	providers: {
		list: ProviderDTO[];
		count: number;
	};
	setProviders: any;
}>({
	providers: {
		list: [],
		count: null,
	},
	setProviders: (value) => value,
});
